class Toaster
  SECONDS_VISIBLE = 4

  def self.raise(icon, message, variant: :success)
    contents = <<~HTML
      <sl-icon name="#{icon}" slot="icon"></sl-icon>
      #{message}
    HTML

    sl_alert = Elemental.create(%s:sl-alert:) do |a|
      a.variant = variant
      a.duration = SECONDS_VISIBLE * 1000
      %i:click touchmove:.each do |event_type|
        a.add_event_listener(event_type) { a.hide() }
      end
      a.inner_html = contents
    end

    document.body.append sl_alert

    sl_alert.toast()
  end
end

export default Toaster
