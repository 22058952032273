# ruby2js: preset, filters: require

require_relative "../javascript/lib/utils.js"

class RatingStarsElement < HTMLElement
  custom_elements.define "rating-stars", RatingStarsElement

  def connected_callback()
    set_timeout 100 do
      @href = self.get_attribute :href
      self.add_event_listener "sl-change" do |event|
        next if event.target.readonly

        value = event.target.value
        rate(value)
      end
    end
  end

  async def rate(value)
    unless signed_in?()
      # grab the context and id out of the url for rating
      context, context_id = @href[1..].split("/")
      return initiate_sign_up(context.delete_suffix("s"), context_id)
    end

    response = await Daniel.post(@href, rating: value)
    return Toaster.raise("check2-circle", "Thanks for your rating!") if response.ok?

    Toaster.raise(
      "exclamation-octagon",
      "I'm sorry, there was a problem saving your rating. Please contact our support team.",
      variant: :danger
    )
    data = await response.text()
    console.error response, data
  end
end
