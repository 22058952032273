
          var import_meta_document = new DocumentFragment()
          const htmlFrag = "<body>" + "<link server-effect=\"$dsd_css_href('pg_button_link')\" host-effect=\"$dsd_css_href('pg_button_link')\" rel=\"stylesheet\" >\n<link server-effect=\"$dsd_css_href()\" host-effect=\"$dsd_css_href()\" rel=\"stylesheet\" >\n\n<button part=\"button\" iso-effect=\"$attribute('pg-size', .size); $attribute('type', .type); @disabled = .disabled\">\n  <slot name=\"prefix\"></slot>\n\n  <slot></slot>\n\n  <slot name=\"suffix\"></slot>\n</button>\n\n\n" + "</body>"
          const fragment = new DOMParser().parseFromString(htmlFrag, 'text/html')
          import_meta_document.append(...fragment.body.childNodes)

          
  import { HeartElement } from "heartml"
  import { ShadowRootable } from "turbo-shadow"

  class PgButton extends ShadowRootable(HeartElement) {
    static {
      this.template = import_meta_document
      this.properties = {
        variant: {},
        outlined: {},
        size: {},
        type: {},
        name: {},
        disabled: {}
      }
      this.declarativeEffects = { shadow: true }
      this.declarativeEvents = ["click"]
      this.define("pg-button")
    }

    start() {
      this.variant = "primary"
      this.outlined = false
      this.size = "medium"
      this.type = "button"
      this.name = ""
      this.disabled = false
    }

    async connectedCallback() {
      await this.shadowRootAttached

      super.connectedCallback()
    }

    handleClick(event) {
      if (this.type === "submit") {
        event.preventDefault()
        this.closest("form")?.requestSubmit()
      }
    }
  }

          export default import_meta_document
        