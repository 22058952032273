import [ signed_in, initiate_sign_up ], from: "../javascript/lib/utils.js.rb"

class DownloadAnalyticsElement < HTMLElement
  custom_elements.define "download-analytics", DownloadAnalyticsElement

  def connected_callback()
    self.add_event_listener :click, self
  end

  def disconnected_callback()
    self.remove_event_listener :click, self
  end

  def downloadable_type = self.get_attribute(:downloadable)

  def downloadable_id = self.get_attribute(:"downloadable-id")

  def action_href(event) = event.composed_path().find { _1.localName == "pg-link" }.href

  def handle_event(event)
    event.prevent_default()

    unless signed_in()
      initiate_sign_up downloadable_type, downloadable_id
      return
    end

    post_to_analytics "/#{downloadable_type}s/#{downloadable_id}/mark_as_downloaded"

    new_href = action_href(event)
    set_timeout 100 do
      window.location.href = new_href
    end
  end

  async def post_to_analytics(url)
    # We need to wait a bit because, in Safari at least, there's an error trying to send this off
    # while also initiating a file download
    callback = async do
      results = await Daniel.post(url, {})
      console.debug await results.json()
    end

    set_timeout callback, 100 # weird syntax here due to Ruby2JS issue with async timeout
  end
end
