# Used for uploading multiple presets at once
# TODO: maybe move to on-page declarative?
class BulkUploaderElement < HTMLElement
  custom_elements.define "bulk-uploader", BulkUploaderElement

  def uploadfield_target = self.query_selector('[data-bulk-uploader-target="uploadfield"]')
  def fileforms_target = self.query_selector('[data-bulk-uploader-target="fileforms"]')
  def savebtn_target = self.query_selector('[data-bulk-uploader-target="savebtn"]')

  async def connected_callback()
    await import("../../javascript/lib/storage.js")

    set_timeout 0 do
      input = uploadfield_target
      @uploadingfiles = 0
      @processedfiles = 0

      input.add_event_listener :change do |_event|
        # go through the files list and start the upload process in
        # half-second increments per file (otherwise we see browser errors)

        add_times = 0
        upload_index = 0

        input.files.each do |file|
          add_times += 500
          set_timeout add_times do
            upload_file file, upload_index
          end
          upload_index += 1
        end

        # clear the selected files from the input
        input.value = nil
        input.parent_node.class_list.add "is-hidden"
      end
    end
  end

  # called for each file upload
  def upload_file(file, index)
    input = uploadfield_target

    # set up the preset details component:
    preset_details = append_form_component(file.name)

    # special upload url provided by ActiveStorage:
    url = input.dataset.direct_upload_url
    # will call directUploadWillStoreFileWithXHR, see below
    upload = ActiveStorage::DirectUpload.new(file, url, self)

    # start the upload and set up the callback
    upload.create do |error, blob|
      @processedfiles += 1
      if error
        console.warn "Something caused the file upload to fail."
        preset_details.upload_error = error
      else
        # File upload is complete...hide progress and submit form
        preset_details.progress = 100
        preset_details.blob_id = blob["signed_id"]
        preset_details.submit_form()
      end

      savebtn_target.class_list.remove("is-hidden") if index == @processedfiles
    end
  end

  def append_form_component(filename)
    human_name = filename[0...filename.rindex(".")]
    Elemental.create :"preset-details" do |preset_details|
      preset_details.bank_id = self.dataset.bulk_uploader_bank_id
      preset_details.name = human_name
      preset_details.filename = filename
      fileforms_target.append preset_details
    end
  end

  # called by ActiveStorage
  def directUploadWillStoreFileWithXHR(request) # rubocop:disable Naming/MethodName
    @uploadingfiles += 1

    # Set up progress bar handler
    request.upload.add_event_listener :progress do |event|
      percentage = ((event.loaded / event.total) * 100).round
      fileforms_target.query_selector(
        "preset-details:nth-child(#{@uploadingfiles})"
      ).progress = percentage
    end
  end
end
