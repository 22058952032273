class AudioPlayerElement < HeartElement
  self.template = html <<~HTML
    <link rel="stylesheet" host-effect="$dsd_css_href('shared')" />
    <link rel="stylesheet" host-effect="$dsd_css_href()" />
    <div id="audio-player-panel">
      <div id="metadata">
        <p>
          <a host-lazy-effect="@href = .bankURL; @textContent = .bankName" host-event="click#bankLinkClick">
          </a>
        </p>
        <p><strong host-lazy-effect="@textContent = .soundclipName"></strong></p>
      </div>

      <div id="playback-controls" style="display: flex; gap: 1rem">
        <template id="audio-blueprint">
          <audio type="audio/mp3"></audio>
        </template>
        <button host-event="click#togglePlayback">
          <img src="/animated-spinner.svg" class="spinner" host-lazy-effect="$show(.loading)" />
          <sl-icon library="linea" host-effect="@name = .playingIconName"></sl-icon>
        </button>
        <sl-progress-bar
          max="100"
          host-event="click#seek"
          host-lazy-effect="@value = .progressPercentage"
        ></sl-progress-bar>

        <sl-range host-event="sl-input#updateVolume" min="0" max="10" value="10" tooltip="none"></sl-range>
        <button host-event="click#toggleMute" style="font-size: 1rem; padding-top: 5px; margin-left: -5px">
          <sl-icon library="linea" host-effect="@name = .muteIconName"></sl-icon>
        </button>
      </div>

      <button aria-label="Close Audio Player" host-event="click#close" class="close">
        <sl-icon library="linea" name="arrows/remove"></sl-icon>
      </button>
    </div>
  HTML

  self.properties = {
    open: {},
    playing: {},
    loading: {},
    muted: {},
    progress_percentage: { attribute: "progress-percentage" },
    bankURL: { memoize: true },
    bank_name: { memoize: true },
    soundclip_name: { memoize: true },
  }

  self.declarative_effects = { shadow: true }
  self.declarative_events = %i(click sl-input keydown)

  self.queries = {
    shadow: {
      playback_controls: "#playback-controls",
      audio_element: "audio",
      audio_blueprint: "#audio-blueprint",
      volume_slider: "sl-range",
    },
  }

  define "audio-player"

  def start()
    # Whether or not the player is shown
    self.open = false

    # The current state of the audio playback
    self.playing = false

    # Trigger the loading state before mp3 downloads
    self.loading = false

    # Track if the volume is at 0 or intentionally muted
    self.muted = false

    # Percentage of the current playing location within the audio file
    self.progress_percentage = 0

    @soundclip = signal(nil)
  end

  def connected_callback()
    super()

    document.add_event_listener :keydown, self

    # Don't apply transition until well after its loaded
    set_timeout(500) { self.set_attribute :initialized, "" }
  end

  def disconnected_callback()
    document.remove_event_listener :keydown, self
  end

  # Play a new soundclip, or resume current one
  def play(soundclip) # rubocop:todo Metrics
    if soundclip == @soundclip.value
      self.audio_element.play()
    else
      self.playback_controls.query_selector("button").focus()
      self.loading = false
      stop()
      self.audio_element&.remove()
      @soundclip.value = soundclip
      self.loading = true

      # Setup audio element
      new_audio = self.audio_blueprint.content.cloneNode(true)
      self.playback_controls.prepend new_audio
      self.audio_element.tap do |audio_el|
        audio_el.add_event_listener :play, self
        audio_el.add_event_listener :pause, self
        audio_el.add_event_listener :timeupdate, self
        audio_el.src = soundclip.button.href
      end

      update_volume nil, self.volume_slider
      self.audio_element.autoplay = true
      self.open = true
    end
  end

  # Stop playing the soundclip
  def stop()
    return unless @soundclip.value

    self.audio_element.pause()
    @soundclip.value.stop()
  end

  # Resume playback of the soundclip
  def resume()
    return unless @soundclip.value

    self.audio_element.play()
    @soundclip.value.resume()
  end

  # Close the audio player
  def close(event = nil)
    if @soundclip.value
      stop()
      set_timeout 400 do
        @soundclip.value = nil
      end
    end

    self.open = self.loading = false
  end

  ### Computes ###

  def bank_u_r_l_memoizing() = @soundclip.value&.bank_information&.link
  def bank_name_memoizing() = @soundclip.value&.bank_information&.name
  def soundclip_name_memoizing() = @soundclip.value&.title

  ### Internal Event Handlers ###

  def bank_link_click(event)
    Turbo.visit event.target.href
    close()
  end

  # Toggle between playing and stopping
  def toggle_playback()
    self.playing ? stop() : resume()
  end

  # Seek to a new position in the soundclip audio
  def seek(event)
    return unless @soundclip.value

    progress_bar = event.target
    self.audio_element.current_time = self.audio_element.duration * (
                                        event.offset_x / progress_bar.client_width
                                      )
    self.audio_element.play()
  end

  def update_volume(event = nil, slider = nil)
    return unless @soundclip.value

    slider ||= event.target
    self.audio_element.volume = slider.value.to_f / 10
    self.muted = self.audio_element.muted = slider.value.to_i == 0 # rubocop:todo Style/NumericPredicate
  end

  def toggle_mute()
    self.muted = self.audio_element.muted = !self.audio_element.muted
    self.volume_slider.value = self.audio_element.muted ? 0 : self.audio_element.volume * 10
  end

  def handle_keydown(event)
    case event.key
    when "Escape"
      close()
    when " "
      next unless event.target === document.body && open?

      event.prevent_default()
      toggle_playback()
    end
  end

  def handle_play()
    self.loading = false
    @soundclip.value.resume()
    self.playing = true
  end

  def handle_pause()
    @soundclip.value.stop()
    self.playing = false
    current_soundclip = @soundclip.value

    return unless self.progress_percentage == 100

    set_timeout 500 do
      current_soundclip.play_next() if current_soundclip == @soundclip.value
    end
  end

  def handle_timeupdate()
    self.progress_percentage = (
      self.audio_element.current_time / self.audio_element.duration * 100
    ).to_i
  end

  ### Icon states ###

  def playing_icon_name = self.playing ? "music/pause_button" : "music/play_button"
  def mute_icon_name = self.muted ? "music/volume_down" : "music/volume_up"
end
