class TopbarElement < HeartElement
  self.declarative_effects = { light: true }

  self.declarative_events = %i(click)

  self.queries = {
    light: {
      nav_menu: "nav-menu",
      notifications_wrapper: "notifications-wrapper",
      notifications_dropdown_template: "#notifications-dropdown",
      notifications_layout: "notifications-wrapper > layout-box",
      notifications_backdrop: "#notifications-backdrop",
    },
  }

  define "top-bar"

  def open_menu(event)
    button = event.target
    button.class_list.toggle "is-active"
    button.aria_expanded = button.class_list.contains?("is-active") ? "true" : "false"

    self.nav_menu.classList.toggle "is-active"
  end

  def open_notifications(event)
    wrapper = self.notifications_wrapper
    if wrapper.children.empty?
      wrapper.append self.notifications_dropdown_template.content.clone_node(true)
    end

    self.notifications_backdrop.style.display = "block"
    self.notifications_layout.style.display = "block"
  end

  def close_notifications(event)
    self.notifications_backdrop.style.display = "none"
    self.notifications_layout.style.display = "none"
    self.notifications_layout.query_selector_all("notification-dot").each { _1.remove() }
  end
end
