class SoundclipElement < HeartElement
  self.properties = {
    playing: {},
    bank: {},
    order: {},
  }

  self.declarative_events = %i(click)

  self.queries = {
    light: {
      button: "pg-link",
      title_text: "[soundclip-button-target='title']",
      icon: "sl-icon",
    },
  }

  define "soundclip-button"

  def start()
    self.playing = false
    self.bank = ""
    self.order = 0
  end

  def play(event = nil)
    event&.prevent_default()
    player = document.query_selector("audio-player")
    if self.playing
      player.stop() # player will then call the stop method
    else
      resume()
      player.play self
    end
  end

  # Plays the next soundclip within the current box
  def play_next()
    return unless self.order.present?

    self
      .closest(".box")
      .query_selector("soundclip-button[order='#{self.order + 1}']")
      &.play()
  end

  def stop()
    self.playing = false
    self.icon.name = "music/play_button"
  end

  def resume()
    self.playing = true
    self.icon.name = "music/pause_button"
  end

  def bank_information
    bank_id, bank_name = self.bank.split(":::")

    {
      link: "/banks/#{bank_id}",
      name: bank_name,
    }
  end

  def title
    soundclip_title = self.title_text.text_content.strip
    return "Sound Demo" if soundclip_title == bank_information.name

    soundclip_title
  end
end
