
          var import_meta_document = new DocumentFragment()
          const htmlFrag = "<body>" + "<link server-effect=\"$dsd_css_href('pg_button_link')\" host-effect=\"$dsd_css_href('pg_button_link')\" rel=\"stylesheet\" >\n\n<a iso-effect=\"@href = .href; $attribute('pg-size', .size)\">\n  <slot name=\"prefix\"></slot>\n\n  <slot></slot>\n\n  <slot name=\"suffix\"></slot>\n</a>\n\n<style>\n  a {\n    text-decoration: none;\n  }\n\n  :host([shadowed]) a {\n    color: var(--accent-color);\n    background-color: transparent;\n    border: none;\n    border-color: transparent;\n    box-shadow: -15px -15px 20px rgba(255, 255, 255, 0.03),\n      0px -10px 10px rgba(255, 255, 255, 0.05), 15px 15px 20px rgba(0, 0, 0, 0.15),\n      0px 10px 10px rgba(0, 0, 0, 0.1), -3px -3px 4px rgba(255, 255, 255, 0.05),\n      3px 3px 4px rgba(0, 0, 0, 0.1), inset 4px 4px 10px rgba(255, 255, 255, 0.05),\n      inset -4px -4px 10px rgba(0, 0, 0, 0.05);\n  }\n\n  :host([shadowed]) a:hover {\n    color: var(--text-color);\n    background-color: var(--accent-hover-color);\n  }\n</style>\n\n\n" + "</body>"
          const fragment = new DOMParser().parseFromString(htmlFrag, 'text/html')
          import_meta_document.append(...fragment.body.childNodes)

          
  import { HeartElement } from "heartml"
  import { ShadowRootable } from "turbo-shadow"

  class PgLink extends ShadowRootable(HeartElement) {
    static {
      this.template = import_meta_document
      this.properties = {
        href: {},
        variant: {},
        outlined: {},
        size: {},
        shadowed: {},
      }
      this.declarativeEffects = { shadow: true }
      this.define("pg-link")
    }

    start() {
      this.href = ""
      this.variant = "primary"
      this.outlined = false
      this.size = "medium"
      this.type = "button"
      this.shadowed = false
    }

    async connectedCallback() {
      await this.shadowRootAttached

      super.connectedCallback()
    }
  }

          export default import_meta_document
        