class ToggleFormSubmitElement < HTMLElement
  custom_elements.define "toggle-form-submit", ToggleFormSubmitElement

  def connected_callback()
    self.add_event_listener "sl-change", self
  end

  def disconnected_callback()
    self.remove_event_listener "sl-change", self
  end

  def handle_event(event)
    return unless event.target.local_name == "sl-switch"

    self.query_selector("form").requestSubmit()
  end
end
