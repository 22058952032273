class NewCommentElement < HeartElement
  self.properties = {
    edit_label: { attribute: "edit-label" },
    reply_label: { attribute: "reply-label" },
  }

  self.declarative_events = %i("submit")

  self.queries = {
    light: {
      form: ":scope > form",
      parent_id: "input[name='parent_id']",
      editing_comment_id: "input[name='editing_comment_id']",
      context: "[new-comment-target='context']",
      body: "autogrow-textarea",
      new_name: "[new-comment-target='new_name']",
      submit_button: "pg-button[type='submit']",
    },
  }

  define "new-comment"

  def start()
    self.edit_label = ""
    self.reply_label = ""
  end

  def connected_callback()
    super

    this.query_selector("form").addEventListener("submit", this)
  end

  def disconnected_callback()
    super

    this.query_selector("form").removeEventListener("submit", this)
  end

  def reset()
    self.form.reset()
    self.context.inner_html = ""
    self.parent_id.value = ""
    self.editing_comment_id.value = ""
  end

  def start_editing(context:, replying_to:, comment_id:, body:)
    @mode = context

    case context
    when :reply
      self.context.inner_html =
        "<p>#{self.reply_label} <strong>#{replying_to.strip()}</strong>:</p>"
      self.parent_id.value = comment_id
    when :edit
      self.context.inner_html = "<p>#{self.edit_label}:</p>"
      self.editing_comment_id.value = comment_id
      self.body.value = body
    end
    self.body.scroll_into_view block: :center
    self.body.focus()
  end

  async def handle_submit(event) # rubocop:disable Metrics/MethodLength, Metrics/CyclomaticComplexity, Metrics/PerceivedComplexity
    event.prevent_default()

    if self.new_name&.query_selector(:input)&.value == ""
      self.new_name.class_list.remove("is-hidden")
      self.new_name.query_selector(:input).focus()
      return
    end

    action = self.form.get_attribute(:action)
    editing_id = self.editing_comment_id.value
    form_data = FormData.new self.form
    self.submit_button.disabled = true

    if editing_id.present?
      response = await Daniel.put("#{action}/#{editing_id}", form_data)
      self.submit_button.disabled = false

      if response.ok?
        markup = await response.text()
        document.query_selector(%(bank-comment[comment-id="#{editing_id}"])).outer_html = markup
        document
          .query_selector(%(bank-comment[comment-id="#{editing_id}"]))
          .scroll_into_view(block: :center)
        Toaster.raise("check2-circle", "Your comment has been updated.")
      else
        errors = await response.json()
        raise errors.join(", ")
      end
    else
      response = await Daniel.post(self.form.get_attribute("action"), form_data)
      self.submit_button.disabled = false

      if response.ok?
        markup = await response.text()
        place_new_comment_node markup
      else
        errors = await response.json()
        raise Error, errors.join(", ")
      end
    end

    reset()

  # Bail at any validation point
  rescue Error => e
    self.submit_button.disabled = false

    Toaster.raise(
      "exclamation-octagon",
      "Oops! Problem saving comment: #{e}",
      variant: :danger
    )
  end

  def place_new_comment_node(markup)
    tmpl = document.create_element(:template)
    tmpl.inner_html = markup
    new_node = tmpl.content.clone_node(true)
    comment = new_node.first_child

    if @mode == :reply
      document
        .query_selector(%(bank-comment[comment-id="#{self.parent_id.value}"]))
        .after(new_node)
    else
      self.parent_node.query_selector(".comments-list").prepend(new_node)
    end
    comment.scroll_into_view block: :center
    @mode = null

    self.new_name&.remove()
    Toaster.raise "check2-circle", "Your comment has been posted."
  end
end
