import "./trix_element.scss"

class FormTrix < HTMLElement
  custom_elements.define "form-trix", FormTrix

  def trix_editors = self.query_selector_all("trix-editor")

  async def connected_callback()
    await import("trix/dist/trix.js")

    trix_editors.each do |trix|
      trix.add_event_listener("trix-file-accept") { _1.prevent_default() }
    end
  end
end
