class PluginEditorElement < HTMLElement
  custom_elements.define "plugin-editor", PluginEditorElement

  def type_select = self.query_selector("#plugin_type_list")

  def connected_callback()
    set_timeout 0 do
      # Don't allow submission via Enter from sl-input fields
      self.add_event_listener(:keydown, ->(event) do
        if event.target.localName == "sl-input" && event.key === "Enter"
          event.prevent_default()
          event.stop_propagation()
        end
      end, true)

      set_up_tags_input type_select, "/plugins/types.json"
    end
  end

  def set_up_tags_input(input_element, search_url)
    input_element.add_event_listener("sl-tags-update", ->(event) do
      event.target.query_selector("input[type=hidden]").value = event.detail.tags.map do |tag|
        tag.value
      end.join(", ")
    end)

    search_callback = async do |event|
      resp = await Daniel.get("#{search_url}?q=#{encodeURIComponent(event.detail.value)}")
      next unless resp.ok

      (await resp.json()) => { results: }
      event.target.update_menu results
    end

    input_element.add_event_listener "sl-tags-search-input", search_callback
  end
end
