class BankEditorElement < HTMLElement
  custom_elements.define "bank-editor", BankEditorElement

  def plugin_select = self.query_selector("#bank_plugin_id")
  def tags_select = self.query_selector("#bank_tag_list")

  def connected_callback()
    set_timeout 0 do
      # Don't allow submission via Enter from sl-input fields
      self.add_event_listener(:keydown, ->(event) do
        if event.target.localName == "sl-input" && event.key === "Enter"
          event.prevent_default()
          event.stop_propagation()
        end
      end, true)

      set_up_tags_input plugin_select, "/plugins.json", process_plugins: true
      set_up_tags_input tags_select, "/tags.json"
    end
  end

  def set_up_tags_input(input_element, search_url, process_plugins: false)
    if process_plugins
      @existing_help_content =
        input_element.closest("form-field").query_selector("p.help").inner_html
    end

    input_element.add_event_listener "sl-tags-update", ->(event) do
      event.target.query_selector("input[type=hidden]").value = event.detail.tags.map do |tag|
        tag.value
      end.join(", ")

      process_plugins_input(event, input_element) if process_plugins
    end

    search_callback = async do |event|
      resp = await Daniel.get("#{search_url}?q=#{encodeURIComponent(event.detail.value)}")
      next unless resp.ok

      (await resp.json()) => { results: }
      event.target.update_menu results
    end

    input_element.add_event_listener "sl-tags-search-input", search_callback
  end

  def process_plugins_input(event, input_element)
    if event.detail.tags.length > 0 && # rubocop:todo Style
        event.detail.tags.first[:value] == event.detail.tags.first[:label]
      input_element.closest("form-field").query_selector("p.help").replace_children(
        self.query_selector("#missing-plugin").content.clone_node(true).first_element_child
      )
    else
      input_element.closest("form-field").query_selector("p.help").inner_html =
        @existing_help_content
    end
  end
end
