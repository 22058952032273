import "trix/dist/trix.css"
import "vanilla-hcaptcha"

/* Set up Shoelace Icon support */
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"
import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library.js"

setBasePath("/shoelace-assets")
registerIconLibrary("linea", {
  resolver: name => `/lineaicons/${name}.svg`
})

/* Set up Shoelace */
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"

import "@shoelace-style/shoelace/dist/components/alert/alert.js"
import "@shoelace-style/shoelace/dist/components/button/button.js"
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown.js"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button.js"
import "@shoelace-style/shoelace/dist/components/input/input.js"
import "@shoelace-style/shoelace/dist/components/menu/menu.js"
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.js"
import "@shoelace-style/shoelace/dist/components/range/range.js"
import "@shoelace-style/shoelace/dist/components/rating/rating.js"
import "@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js"
import "@shoelace-style/shoelace/dist/components/switch/switch.js"
import "@shoelace-style/shoelace/dist/components/tag/tag.js"

import "sl-tags-input"

import "../styles/index.scss"

import * as Turbo from "@hotwired/turbo-rails"
import * as TurboShadow from "turbo-shadow"
import "lib/custom_actions.js.rb"
import "lib/turbo_transitions.js.rb"

import Heartml from "heartml"

Heartml.plugins.lightTemplate = {
  connected(element) {
    const tagName = element.localName
    const lightHTML = document.querySelector(
        `heart-ml[tag-name="${tagName}"] > template[data-light-html]`
      ).content.cloneNode(true)

    element.replaceChildren(lightHTML)
  }
}

Heartml.plugins.declarativeEffects.directives.dsd_css_href = (instance, node, custom_name ) => {
  const base = custom_name || instance.localName.replace(/-/g, "_")
  node.href = window.dsdDigestedAssets[`${base}.dsd.css`]
}

import "heartml/utils/DeclarativeHeartElement.js"

// Import all JavaScript & CSS files from app/components
import components from "../components/**/*.{js,jsx,js.rb,heartml}"

window.addEventListener("turbo:load", () => {
  document.documentElement.style.scrollBehavior = "smooth"
})

window.addEventListener("turbo:visit", () => {
  document.documentElement.style.scrollBehavior = "auto"
})
