import [ save_sign_in_context, initiate_sign_up ], from: "../javascript/lib/utils.js.rb"

class SaveContextElement < HTMLElement
  custom_elements.define "save-context", SaveContextElement

  def connected_callback()
    self.add_event_listener :click, self
  end

  def disconnected_callback()
    self.remove_event_listener :click, self
  end

  def handle_event(event)
    if event.target.dataset.save_context == "sign_in"
      event.prevent_default()
      save_sign_in_context "bank", self.get_attribute("path")
    elsif event.target.dataset.save_context == "register"
      event.prevent_default()
      initiate_sign_up "bank", self.get_attribute("path")
    end
  end
end
