class SoundclipUploaderElement < HeartElement
  self.declarative_events = [
    "click",
    "input",
    "direct-upload:start",
    "direct-upload:progress",
    "ajax:error",
  ]

  self.queries = {
    light: {
      upload_form: "form",
      progress_bar: "progress",
      upload_field: "input[type='file']",
    },
  }

  define "soundclip-uploader"

  def handle_direct_upload_start()
    self.progress_bar.class_list.remove "is-hidden"
  end

  def handle_direct_upload_progress(event)
    event.detail => { progress: }

    self.progress_bar.value = progress
    self.progress_bar.inner_html = "#{progress}%"
  end

  def handle_ajax_error(event)
    console.error(event, self)
    self.progress_bar.parent_node.class_list.add :hide
    self.upload_field.disabled = false
    self.upload_field.value = null
    if event.detail[0] && event.detail[0].status == "error"
      alert "Oops! #{event.detail[0].error}"
    else
      alert "I'm sorry, there was a server error preventing the form from being submitted. " \
            "Please contact us if you continue to experience this problem."
    end
  end

  def file_ready_to_upload(event)
    self.upload_form.request_submit() if event.target.files.length > 0 # rubocop:disable Style
  end
end
