# For Ruby definitions see: config/initializers/turbo_stream_actions.rb

def toggle_class()
  payload = JSON.parse(self.template_content.text_content)
  self.target_elements.each do |element|
    element.class_list.toggle(payload[:name], payload[:set])
  end
end

def set_property()
  payload = JSON.parse(self.template_content.text_content)
  self.target_elements.each do |element|
    element[payload[:name]] = payload[:value]
  end
end

def redirect_to()
  payload = JSON.parse(self.template_content.text_content)
  set_timeout(payload[:delay] || 0) do
    Turbo.visit(payload[:url])
  end
end

Turbo.StreamActions.merge!({
  "toggle_class" => toggle_class,
  "set_property" => set_property,
  "redirect_to"  => redirect_to,
})
