class AutogrowTextarea < HeartElement
  self.properties = {
    controlclass: {},
    minheight: {},
    maxheight: {},
    name: {},
    placeholder: {},
    value: {},
  }

  self.declarative_effects = { light: true }
  self.declarative_events = %i(input)

  define "autogrow-textarea"

  def start()
    self.controlclass = ""
    self.minheight = 50
    self.maxheight = 150
    self.name = ""
    self.placeholder = ""
    self.value = ""
  end

  def connected_callback()
    self.inner_html = <<~HTML
      <textarea host-effect="
        @name = .name;
        @className = .controlclass;
        @placeholder = .placeholder;
        @value = .value
      "
      host-event="input#alterSize">#{self.inner_html}</textarea>
    HTML

    super()
  end

  def alter_size()
    clear_timeout @_debouncer

    @_debouncer = set_timeout(75) do
      textarea = self.query_selector(:textarea)
      textarea.style.height = "#{self.minheight}px"
      scroll_height = [textarea.scroll_height, self.maxheight].min()
      textarea.style.height = "#{scroll_height}px" unless scroll_height < self.minheight
    end
  end

  def focus() = self.query_selector(:textarea).focus()
end
